import {
  BookingTransportPlaces,
  Floor,
  SeatCategory,
  TransportsSchemeItem,
} from '@klr/api-connectors'
import { getPlaceInScheme, isSeat } from '@klr/schemes'

import { ToggledPlace } from '../booking.types'

import {
  AvailableTransportPlaces,
  BookingCheckoutStore,
  PassengerItem,
  ToggleSelectedData,
  TransportSelectedData,
  TransportSelectedPlaces,
} from './booking-checkout-store.types'

export function getSelectedPlacesFromScheme(transportData: TransportsSchemeItem[]) {
  return transportData.reduce<TransportSelectedPlaces[]>((acc, currentValue) => {
    const selectedPlaces = currentValue.selectedPlaces
      .map((item) => {
        const seat = getPlaceInScheme(currentValue.transportScheme, item)

        if (seat) {
          return {
            seat: seat.place,
            category: seat.category,
            priceForCategory: seat.price_for_category,
          }
        }

        return null
      })
      .filter(Boolean) as TransportSelectedPlaces['selectedPlaces']

    acc.push({
      transportId: currentValue.transportId,
      selectedPlaces,
    })

    return acc
  }, [])
}

export function transformFreeMainTransportPlaces(
  availableMainTransportPlaces: AvailableTransportPlaces,
  excludedPlaces: number[] = []
): AvailableTransportPlaces {
  const filteredAvailablePlaces = Object.values(availableMainTransportPlaces)[0].filter((item) => {
    return !excludedPlaces.includes(item.place)
  })

  return {
    [Object.keys(availableMainTransportPlaces)[0]]: [
      ...filteredAvailablePlaces
        .filter((item) => item.category === SeatCategory.STANDARD)
        .sort((a, b) => a.place - b.place),
      ...filteredAvailablePlaces
        .filter((item) => item.category !== SeatCategory.STANDARD)
        .sort((a, b) => a.place - b.place),
    ],
  }
}

function getAllPassengerPlacesByMainTransport(passengers: PassengerItem[], transportId: number) {
  return passengers.reduce<number[]>((acc, currentValue) => {
    const selectedPlace = currentValue.selectedPlaces.find(
      (selectedItem) => selectedItem.transportId === transportId
    )

    if (selectedPlace) {
      acc.push(selectedPlace.seat)
    }

    return acc
  }, [])
}

function getFilteredAvailablePlacesBySelected(
  availableFreePlaces: BookingTransportPlaces[],
  selectedPlaces: number[]
) {
  return availableFreePlaces.filter((item) => !selectedPlaces.includes(item.place))
}

function getAvailablePlacesWithRemovedPassenger(
  availableFreePlaces: BookingTransportPlaces[],
  selectedPlaces: PassengerItem['selectedPlaces'],
  mainTransportId: number
): BookingTransportPlaces[] {
  const availableFreePlace = selectedPlaces.find((item) => item.transportId === mainTransportId)

  if (
    availableFreePlace &&
    !availableFreePlaces.find((item) => item.place === availableFreePlace.seat)
  ) {
    return transformFreeMainTransportPlaces({
      [mainTransportId]: [
        {
          place: availableFreePlace.seat,
          category: availableFreePlace.category,
          status: 'available',
        },
        ...availableFreePlaces,
      ],
    })[mainTransportId]
  }

  return availableFreePlaces
}

interface TransformPassengerFreePlacesProps {
  availableFreeTransportPlaces: AvailableTransportPlaces
  mainTransportId: number
  passengers: PassengerItem[]
  oldPassenger?: PassengerItem
}

export function transformPassengerFreePlaces({
  availableFreeTransportPlaces,
  mainTransportId,
  passengers,
  oldPassenger,
}: TransformPassengerFreePlacesProps): PassengerItem[] {
  // get all selected places by main transport
  const passengerSelectedPlaces = getAllPassengerPlacesByMainTransport(passengers, mainTransportId)

  // get available places for the main transport except the selected ones
  let availableFreePlaces = getFilteredAvailablePlacesBySelected(
    availableFreeTransportPlaces[mainTransportId] ?? [],
    passengerSelectedPlaces
  )

  // if we remove a passenger, we need to add his place to the available places
  if (oldPassenger) {
    availableFreePlaces = getAvailablePlacesWithRemovedPassenger(
      availableFreePlaces,
      oldPassenger.selectedPlaces,
      mainTransportId
    )
  }

  return passengers.map((item) => {
    const selectedPlace = item.selectedPlaces.find(
      (selectedItem) => selectedItem.transportId === mainTransportId
    )

    // if we have a selected place, we need to duplicate it to the passenger's free place
    if (selectedPlace) {
      return {
        ...item,
        place: selectedPlace.seat,
      }
    }

    // if we don't have a selected place, we need to assign a free place to the passenger
    // and remove it from the available places
    return {
      ...item,
      place: availableFreePlaces.shift()?.place || item.place,
    }
  })
}

interface SetPriceAndSelectedPlacesToPassengersProps {
  availableFreeTransportPlaces: AvailableTransportPlaces
  mainTransportId: number
  passengers: PassengerItem[]
  selectedPlaces: TransportSelectedPlaces[]
}

export function setPriceAndSelectedPlacesToPassengers({
  availableFreeTransportPlaces,
  mainTransportId,
  passengers,
  selectedPlaces,
}: SetPriceAndSelectedPlacesToPassengersProps) {
  const passengerList = passengers.map((item, index) => {
    return {
      ...item,
      price: selectedPlaces.reduce((acc, currentValue) => {
        if (currentValue.selectedPlaces[index]) {
          return acc + currentValue.selectedPlaces[index].priceForCategory
        }

        return acc
      }, item.price),
      selectedPlaces: selectedPlaces
        .map((selectedItem) => {
          if (selectedItem.selectedPlaces[index]) {
            return {
              transportId: selectedItem.transportId,
              ...selectedItem.selectedPlaces[index],
            }
          }

          return null
        })
        .filter(Boolean) as PassengerItem['selectedPlaces'],
    }
  })

  return transformPassengerFreePlaces({
    availableFreeTransportPlaces,
    mainTransportId,
    passengers: passengerList,
  })
}

function changeSelectedDataInScheme(schemes: Floor[], newPlace: number, oldPlace?: number) {
  return schemes.map((scheme) => {
    return scheme.map((item) => {
      return item.map((placeItem) => {
        if (isSeat(placeItem) && placeItem.place === oldPlace) {
          return {
            ...placeItem,
            selected: false,
            available: true,
          }
        }

        if (isSeat(placeItem) && placeItem.place === newPlace) {
          return {
            ...placeItem,
            selected: !placeItem.selected,
            available: !placeItem.available,
          }
        }

        return placeItem
      })
    })
  })
}

export function changeSelectedDataInTransport(
  transportData: TransportsSchemeItem[],
  selectedData: TransportSelectedData
) {
  return transportData.map((item) => {
    if (item.transportId === selectedData.transportId) {
      return {
        ...item,
        transportScheme: changeSelectedDataInScheme(
          item.transportScheme,
          selectedData.newPlace,
          selectedData.oldPlace
        ),
      }
    }

    return item
  })
}

export function changeSelectedDataInPassengers(
  passengerItems: PassengerItem[],
  selectedData: ToggleSelectedData
) {
  const { newPlace, oldPlace, passengerIndex, transportId } = selectedData

  return passengerItems.map((item, index) => {
    if (index === passengerIndex) {
      const candidateIndex = item.selectedPlaces.findIndex(
        (place) => place.transportId === transportId
      )

      const selectedPlace = {
        transportId,
        seat: newPlace.place,
        category: newPlace.category,
        priceForCategory: newPlace.price_for_category,
      }

      if (candidateIndex !== -1) {
        return {
          ...item,
          price:
            item.price -
            item.selectedPlaces[candidateIndex].priceForCategory +
            (oldPlace ? selectedPlace.priceForCategory : 0),
          selectedPlaces: [
            ...item.selectedPlaces.slice(0, candidateIndex),
            oldPlace ? selectedPlace : null,
            ...item.selectedPlaces.slice(candidateIndex + 1),
          ].filter(Boolean) as PassengerItem['selectedPlaces'],
        }
      }

      return {
        ...item,
        price: item.price + selectedPlace.priceForCategory,
        selectedPlaces: [...item.selectedPlaces, selectedPlace],
      }
    }

    return item
  })
}

export function removeSelectedDataFromPassengers(
  passengerItems: PassengerItem[],
  selectedData: ToggledPlace
) {
  const { place, transportId } = selectedData

  const passengerIndex = passengerItems.findIndex((item) =>
    item.selectedPlaces.some((selectedPlace) => selectedPlace.seat === place.place)
  )

  if (passengerIndex === -1) return passengerItems

  return passengerItems.map((item, index) => {
    if (index === passengerIndex) {
      return {
        ...item,
        price: item.price - place.price_for_category,
        selectedPlaces: item.selectedPlaces.filter((place) => place.transportId !== transportId),
      }
    }

    return item
  })
}

interface SelectedPlaceFromCSProps {
  state: BookingCheckoutStore['callStackTransportSelectedPlaces']
  transportId: number
  place: number
}

interface RemoveOldAndAddNewPlaceToCSProps {
  state: BookingCheckoutStore['callStackTransportSelectedPlaces']
  transportId: number
  oldPlace: number
  newPlace: number
}

export function initializeCallStackFromPassengers(passengers: PassengerItem[]) {
  return passengers.reduce<BookingCheckoutStore['callStackTransportSelectedPlaces']>(
    (acc, currentValue) => {
      currentValue.selectedPlaces.forEach((item) => {
        if (acc[item.transportId]) {
          acc[item.transportId].push(item.seat)
        } else {
          acc[item.transportId] = [item.seat]
        }
      })

      return acc
    },
    {}
  )
}

export function removeSelectedPlaceFromCS({ state, transportId, place }: SelectedPlaceFromCSProps) {
  const newState = { ...state }

  if (newState[transportId]) {
    newState[transportId] = newState[transportId].filter((item) => item !== place)
  }

  return newState
}

export function removeOldAndAddNewPlaceToCS({
  state,
  transportId,
  oldPlace,
  newPlace,
}: RemoveOldAndAddNewPlaceToCSProps) {
  const newState = { ...state }

  if (newState[transportId]) {
    newState[transportId] = [...newState[transportId].filter((item) => item !== oldPlace), newPlace]
  }

  return newState
}

export function removeFirstAndAddSelectedPlaceToCS({
  state,
  transportId,
  place,
}: SelectedPlaceFromCSProps) {
  const newState = { ...state }

  if (newState[transportId]) {
    newState[transportId] = [...newState[transportId].slice(1), place]
  }

  return newState
}

export function addNewPlaceToCS({ state, transportId, place }: SelectedPlaceFromCSProps) {
  return Object.keys(state).length !== 0
    ? {
        ...state,
        [transportId]: [...state[transportId], place],
      }
    : {
        [transportId]: [place],
      }
}

export function setupTransportData(
  initialTransportData: TransportsSchemeItem[],
  newRouteName: string
) {
  return initialTransportData.reduce<TransportsSchemeItem[]>((acc, currentValue) => {
    const transportItem = acc.find((item) => item.transportId === currentValue.transportId)

    if (!transportItem) {
      acc.push(currentValue)
    } else {
      acc[acc.length - 1].routeName = newRouteName
    }

    return acc
  }, [])
}
