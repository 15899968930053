import { lazy, ReactNode, useCallback } from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { OrderDetail } from '@klr/api-connectors'
import { DrawerDetail } from '@klr/shared'

import { OrderDetailContext } from './order-detail.context'

const DetailOrder = lazy(() => import('../components/DetailOrder'))

interface OrderDetailProviderProps {
  children: ReactNode
  marketplaceURL: string
}

export const OrderDetailProvider = ({ children, marketplaceURL }: OrderDetailProviderProps) => {
  const [queryOrderId, setQueryOrderId] = useQueryParam(
    'order',
    withDefault(StringParam, undefined)
  )

  const showOrderDetail = useCallback(
    (orderId: OrderDetail['id']) => {
      // TODO: remove type coercion
      setQueryOrderId(orderId.toString())
    },
    [setQueryOrderId]
  )

  const closeOrderDetail = useCallback(() => {
    setQueryOrderId(undefined)
  }, [setQueryOrderId])

  return (
    <OrderDetailContext.Provider value={{ marketplaceURL, showOrderDetail, closeOrderDetail }}>
      {children}
      <DrawerDetail open={!!queryOrderId} onClose={closeOrderDetail}>
        {queryOrderId ? <DetailOrder orderId={queryOrderId} onClose={closeOrderDetail} /> : null}
      </DrawerDetail>
    </OrderDetailContext.Provider>
  )
}
